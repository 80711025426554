@primaryColor: #333;
@linkColor: #bc0023;
@linkHoverColor: lighten(@linkColor, 10%);

:root {
	--primary-color: @primaryColor;
	--primary-highlight-color: lighten(@primaryColor, 10%);
	--primary-disabled-color: lighten(@primaryColor, 20%);

	--link-color: @linkColor;
	--link-hover-color: @linkHoverColor;
}

#headerlogo {
	background-image: url(dtvlogo.svg);
	background-size: auto 40px;
	background-repeat: no-repeat;
	background-position: left center;
	margin-left: 1rem;
}
